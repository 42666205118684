@import '../../styles/variables.scss';

.table-border-bottom {
    border-bottom: 1px solid $border-color !important;
}

.primary-color {
    color: $primary-font-color !important;
}

.fa-up-right-from-square  {
    margin-left: 0.8rem !important;
    font-size: 12px;
}