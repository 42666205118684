@import './../../styles/variables.scss';

h1,
h3 {
    color: $primary-font-color !important;
}

a {
    color: inherit !important;
    text-decoration: inherit !important;
}

.menu-link {
    .line {
        width: 2rem;
        height: 1px;
        background-color: $secondary-font-color;
        margin-right: 1rem;
    }
    &:hover {
        .line {
            background-color: $primary-font-color;
            width: 2.5rem;
        }
        .menu-link-text {
            color: $primary-font-color;
        }
    }
    &:focus {
        .line {
            background-color: $primary-font-color;
            width: 4rem;
        }
        .menu-link-text {
            color: $primary-font-color;
        }
    }
}

.active {
    .line {
        background-color: $primary-font-color;
        width: 4rem;
    }
    .menu-link-text {
        color: $primary-font-color;
    }
}
