//importing variables
@import './variables.scss';

.cursor-pointer {
    cursor: pointer;
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}

.external-link-text {
    a {
        color: $external-link-text-color !important;
        font-weight: 600 !important;
        text-decoration: none !important;
        &:hover {
            color: #5eead4 !important;
        }
    }
}

.selected-external-link-text {
    a {
        font-weight: 600 !important;
        text-decoration: none !important;
        color: #5eead4 !important;
    }
}

.view-full {
    a {
        .fa-arrow-right {
            font-size: 0.7em !important;
            margin-left: 0.5rem !important;
        }
        .fa-arrow-left {
            font-size: 0.7em !important;
            margin-right: 0.5rem !important;
        }
        &:hover {
            .fa-arrow-right {
                margin-left: 0.8rem !important;
            }
            .fa-arrow-left {
                padding-left: -0.8rem !important;
            }
        }
    }
}

.block {
    &:hover {
        border-radius: 0.25rem !important;
        border: 0 solid #e5e7eb !important;
        background-color: #1e293b80;
        .block_title {
            color: #5eead4 !important;
        }
    }
    .fa-up-right-from-square {
        font-size: 0.8em !important;
        margin-left: 0.5rem !important;
    }
}

.skills_content {
    color: #5eead4 !important;
    background-color: #2dd4bf1a;
}

.fs-px-2 {
    font-size: 12px !important;
}

.fs-px-3 {
    font-size: 16px !important;
}

.fs-px-4 {
    font-size: 18px !important;
}

.fs-px-5 {
    font-size: 20px !important;
}

.pb-12 {
    padding-bottom: 6rem !important;
}

.ms-12 {
    margin-left: 6rem !important;
}

.mt-12 {
    margin-top: 6rem !important;
}

.mt-12-half {
    margin-top: 6.5rem !important;
}

.mt-10 {
    margin-top: 4rem !important;
}

.mt-11 {
    margin-top: 5rem !important;
}

.ps-12 {
    padding-left: 6rem !important;
}

.pt-12 {
    padding-top: 6rem !important;
}

.px-12 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
}

.py-12 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}

.max-width-none {
    max-width: none !important;
}

.max-width-2 {
    max-width: 20rem !important;
}

.max-width-large {
    max-width: 1280px !important;
}

.max-width-medium {
    max-width: 1024px !important;
}

.max-width-normal {
    max-width: 768px !important;
}

.min-width-xs-tiny {
    min-width: 100px !important;
}

.list-style-none {
    list-style: none !important;
}

.common-text-color {
    color: $common-text-color !important;
}

.flex-0-5 {
    flex: 0.5;
}

.flex-1 {
    flex: 1;
}

.flex-1-5 {
    flex: 1.5;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.scrollbar-thin {
    scrollbar-width: thin;
}

.border-collapse {
    border-collapse: collapse;
}

.sticky {
    position: sticky;
}

.tcell-sm {
    width: 100px !important;
}

.tcell-md {
    width: 200px !important;
}

.tcell-lg {
    width: 300px !important;
}

.tcell-xl {
    width: 400px !important;
}

.vertical-tx-top {
    vertical-align: text-top !important;
}

.vertical-top {
    vertical-align: top !important;
}

.profile-picture {
    border-radius: 18% !important;
    width: 150px !important;
    height: 200px !important;
}
